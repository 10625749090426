import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LockScreenVideo from "../../assets/lock-screen-widgets.mp4"
import VideoWrapper from "../../components/videoWrapper"

const Page = () => (
  <Layout>
    <Seo title="Siri og snarveier" />
    <div className="longtext">
      <h2>Siri og snarveier</h2>

      <h3>Opprett oppgaver fra lock screen</h3>
      <p>
        Med lock screen widgets (vist over) kan du raskt legge oppgaver inn i
        listen du ønsker, rett fra låst skjermen. Raskere blir det ikke!
      </p>
      <ol>
        <li>Oppgrader til iOS 16, om du ikke har gjort det allerede</li>
        <li>Se video under for oppsett:</li>
      </ol>

      <div style={{ marginTop: "2rem" }}>
        <VideoWrapper id="video_demo" video={LockScreenVideo} />
      </div>

      <h3>Lag egne Snarveier og kommandoer til Siri</h3>

      <h4>Fra Mente (enklest)</h4>
      <ol>
        <li>Gå til Innstillinger -&gt; Siri og Snarveier</li>
        <li>
          Velg mellom å lage en snarvei for å "Vise en liste" eller "Legg til
          oppgave"
        </li>
        <li>
          (valgfritt) under "Når jeg sier" kan du endre uttrykket du bruker for
          å starte snarveien. Dette uttrykket kan du deretter bruke når du
          f.eks. sitter i bilen.
        </li>
        <li>
          (valgfritt) klikk på handlingen under "Utfør" for å endre hvilke
          parametere som er forhåndsutfylt eller ikke.
        </li>
      </ol>

      <h4>Fra Snarveier-appen (mest fleksibelt)</h4>
      <ol>
        <li>Åpne appen "Snarveier"</li>
        <li>Opprett en ny snarvei</li>
        <li>
          Under "Legg til handling" søker du opp Mente og velger mellom{" "}
          <b>Legg til oppgave(r)</b> eller <b>Vis liste</b>
        </li>
      </ol>
    </div>
  </Layout>
)

export default Page
